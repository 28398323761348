import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  addYard,
  fetchYard,
  fetchYardbyId,
  updateYard,
  addYardFromAdmin,
  fetchValidYard,
  addYardFromExisting,
} from "./Yard";

export const YardDetailsAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addYard: addYard(build),
    fetchYard: fetchYard(build),
    fetchValidYard: fetchValidYard(build),
    updateYard: updateYard(build),
    fetchYardbyId: fetchYardbyId(build),
    addYardFromAdmin: addYardFromAdmin(build),
    addYardFromExisting: addYardFromExisting(build),
  }),
  overrideExisting: false,
});

export const {
  useAddYardMutation,
  useLazyFetchYardQuery,
  useLazyFetchValidYardQuery,
  useUpdateYardMutation,
  useLazyFetchYardbyIdQuery,
  useAddYardFromAdminMutation,
  useAddYardFromExistingMutation,
} = YardDetailsAPI;
