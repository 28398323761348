import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faClose, faEye } from "@fortawesome/free-solid-svg-icons";
import { useUploadFileMutation } from "../services/modules/common/file";
import { useRef, useState, useEffect } from "react";

function FileUploader({
  value = [],
  onUpload = () => {},
  onError = () => {},
  name = "",
  required = false,
  style = {},
  showDelete = false,
  onDelete = () => {},
  disabled = false,
  pdfCheck = false,
  accept = "",
  onTypeError = () => {},
  onDownload = () => {},
  showDownload = false,
  ...props
}) {
  const [uploadFile] = useUploadFileMutation();
  const [fileNames, setFileNames] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  // useEffect(() => {
  //   setFileNames(value);
  // }, [value]);

  const handleFileSelect = async (event) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const formData = new FormData();

      const fileNamesArray = [];

      let pdfSelected = false;
      const acceptTypes = accept.split(",");

      [...files].forEach((file) => {
        if (pdfCheck && file.type === "application/pdf") {
          formData.append("file", file);
          fileNamesArray.push(file.name);
          pdfSelected = true;
        } else if (
          acceptTypes.length > 0 &&
          !acceptTypes.some((acceptedType) =>
            file.type.match(acceptedType.trim())
          )
        ) {
          onTypeError();
          return;
        } else {
          formData.append("file", file);
          fileNamesArray.push(file.name);
          pdfSelected = false;
        }
      });

      setFileNames(fileNamesArray);
      if (pdfCheck && !pdfSelected) {
        setErrorMessage("Only PDF files can be uploaded.");
        return; // Exit the function
      } else {
        setErrorMessage("");
      }

      if (formData.has("file")) {
        const response = await uploadFile({ data: formData });
        if (response?.data?.statusCode === 201) {
          const fileNamesOnServer = response?.data?.result.map(
            (i) => i.filename
          );
          onUpload(fileNamesOnServer);
        } else {
          onError(response);
        }
      }
    }
  };
  const ref = useRef();
  return (
    <div className="file-uploader">
      <div className="file-input-container">
        {value.length !== 0 ? (
          <>
            <div className="file_div position-relative ">
              <input
                type="text"
                className="file-input-text px-3 pe-none w-75"
                onChange={handleFileSelect}
                name={name}
                value={
                  fileNames.length < 1
                    ? "File"
                    : `Uploaded: ${fileNames.join(", ")}`
                }
                required={required}
                style={{
                  backgroundColor: "#e6e5e5",
                  border: "none",

                  ...style,
                }}
                disabled={true}
              />
              {showDownload ? (
                <FontAwesomeIcon
                  size={"1.25rem"}
                  style={{
                    color: "#3D5CB8",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  icon={faEye}
                  onClick={onDownload}
                />
              ) : null}
            </div>
            {!disabled && showDelete ? (
              <button
                type="button"
                className="file-remove-button"
                onClick={(value) => {
                  onDelete(value);
                }}
              >
                <FontAwesomeIcon icon={faClose} className="fa-fw" />
              </button>
            ) : null}
          </>
        ) : (
          <>
            <div
              onClick={() => ref.current.click()}
              className="file_div position-relative "
            >
              <span className="choose-file position-absolute ">
                Choose File :
              </span>
              <input
                type="file"
                className="file-input"
                onChange={handleFileSelect}
                name={name}
                style={style}
                disabled={disabled}
                accept={accept}
                {...props}
                ref={ref}
              />
            </div>
            <FontAwesomeIcon
              icon={faPaperclip}
              className="fa-fw file-clip-icon"
            />
          </>
        )}
      </div>
      {errorMessage && (
        <p style={{ color: "red", fontSize: "12px" }}>{errorMessage}</p>
      )}
    </div>
  );
}

export default FileUploader;
