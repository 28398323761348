import {
  RA,
  VESSEL_OWNER,
  PORT_CONSERVATOR,
  NAVAL_ARCHITECTURE,
  SURVEYOR,
  BUILDER,
  YARD,
  ADMIN,
  DATA_ENTRY_OPERATOR,
  DATA_ENTRY_APPROVER,
} from "./constants";

import UserService from "../services/UserService";
export const getRole = () => {
  if (UserService.hasRole(["port-conservator"])) {
    return PORT_CONSERVATOR;
  } else if (UserService.hasRole(["registering-authority"])) {
    return RA;
  } else if (UserService.hasRole(["naval-architect"])) {
    return NAVAL_ARCHITECTURE;
  } else if (UserService.hasRole(["registering-authority"])) {
    return RA;
  } else if (UserService.hasRole(["surveyor"])) {
    return SURVEYOR;
  } else if (UserService.hasRole(["dataentry-operator"])) {
    return DATA_ENTRY_OPERATOR;
  } else if (UserService.hasRole(["data-approver"])) {
    return DATA_ENTRY_APPROVER;
  } else if (UserService.hasRole(["admin"])) {
    return ADMIN;
  } else if (UserService.hasRole(["vessel-owner"])) {
    return VESSEL_OWNER;
  } else if (UserService.hasRole(["builder"])) {
    return BUILDER;
  } else if (UserService.hasRole(["yard-owner"])) {
    return YARD;
  }
};
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Error setting item to local storage:", error);
  }
};

// Get item from local storage
export const getLocalStorageItem = (key, defaultValue = null) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error("Error getting item from local storage:", error);
    return defaultValue;
  }
};

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}-${month}-${year}`;
}

// Function to format a date string or Date object to "yyyy-mm-dd"
export function formatDateNew(inputDate) {
  if (!inputDate) { return ""; }
  const date = new Date(inputDate);
  if (isNaN(date)) {
    return ""; // Handle invalid date
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

function generateYearOptions(startYear, endYear) {
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }
  return years;
}
export const years = generateYearOptions(1970, 2050);
