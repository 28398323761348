import { useEffect, useMemo, useState } from "react";
import {
  useLazyFetchRateByCategoryQuery,
  useLazyFetchRateByApplicationIdQuery,
} from "../services/modules/admin/rates";
import { useAddOrderMutation } from "../services/modules/payment";
import { getLocalStorageItem } from "../utils/utility";
import { Config } from "../config";
import { useRazorPay } from "../hooks/useRazorPay";
import Button from "./Button";
import CommonModal from "./CommonModal";
import { Card } from "react-bootstrap";
import Loader from "./Loader";

function CheckoutPage({
  applicationType,
  applicationId,
  receipt,
  description,
  onSuccess = () => {},
  onFailure = () => {},
}) {
  const [
    fetchRateByApplicationId,
    { data: rateByCategoryData, isSuccess: isRateByCategorySuccess },
  ] = useLazyFetchRateByApplicationIdQuery();
  const [addOrder, { isLoading: isAddOrderLoading }] = useAddOrderMutation();

  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { handlePayment } = useRazorPay();

  const [modalState, setModalState] = useState({
    title: "",
    description: "",
    buttonText: "OK",
    isSuccess: false,
    show: false,
  });

  useEffect(() => {
    const userDetail = getLocalStorageItem("userDetails");
    if (userDetail) setUserInfo(userDetail);
  }, []);

  useEffect(() => {
    if (applicationType && applicationId)
      fetchRateByApplicationId({ category: applicationType, applicationId });
  }, [fetchRateByApplicationId, applicationId, applicationType]);
  useEffect(() => {
    if (!rateByCategoryData) return;
    setIsLoading(false);
  }, [rateByCategoryData]);

  const startPayment = async () => {
    const addOrderResponse = await addOrder({
      data: {
        currency: "INR",
        receipt: receipt,
        applicationId: applicationId,
        applicationType: applicationType,
      },
    });

    if (![200, 201].includes(addOrderResponse?.data?.statusCode)) {
     
      setModalState({
        title: addOrderResponse?.error?.data?.message,
        show: true,
        isSuccess: false,
      });
      return;
    }

    const orderId = addOrderResponse?.data?.result?.id;

    const prefill = {
      email: userInfo?.email || "",
      contact: userInfo?.mobile || "",
    };

    const options = {
      key: Config.RAZORPAY_KEY_ID,
      order_id: orderId,
      name: "Kerala Inland Vessels",
      image: "/assets/icons/logo.svg",
      description: description,
      handler: (_ /* Response */) => {
        setModalState({
          title: "Payment Completed",
          description: `${description} Submitted`,
          show: true,
          isSuccess: true,
        });
      },
      prefill: prefill,
      modal: {
        confirm_close: true,
        ondismiss: async (reason) => {
          // Payment is cancelled (When user cancels payment)
          if (reason === undefined) {
            setModalState({
              title: "Payment Request Cancelled",
              show: true,
              isSuccess: false,
            });
          }
          // Modal is auto closed because of time out
          else if (reason === "timeout") {
            setModalState({
              title: "Payment Request Timed out",
              show: true,
              isSuccess: false,
            });
          }
          // Payment process failed
          else {
            setModalState({
              title: `Payment process failed: ${reason.error.description}`,
              show: true,
              isSuccess: false,
            });
          }
        },
      },
      retry: {
        enabled: false,
      },
      timeout: 900, // Time limit in Seconds
      theme: {
        color: "#3D57A7",
      },
    };

    handlePayment(options);
  };

  const getTaxedAmount = useMemo(() => {
    if (!isRateByCategorySuccess) return 0;
    return rateByCategoryData?.result?.at(0)?.taxableRate;
  }, [isRateByCategorySuccess, rateByCategoryData?.result]);

  if (isLoading) return <Loader />;
  return (
    <div className="d-flex flex-column container">
      <CommonModal
        {...modalState}
        buttonText="OK"
        handleClose={() => {
          setModalState((prev) => {
            return { ...prev, show: false };
          });

          if (modalState.isSuccess) onSuccess();
          else onFailure();
        }}
      />
      <h1>Checkout</h1>

      <div className="overflow-auto">
        <Card className="py-4 px-4 w-50 mx-auto mb-5 gap-2">
          <div className="d-flex justify-content-between">
            <h5>Application Fee</h5>
            <h5>₹{rateByCategoryData?.result?.at(0)?.paymentAmount || 0}</h5>
          </div>
          {rateByCategoryData?.result?.at(0)?.taxApplicable && (
            <>
              {rateByCategoryData?.result?.at(0)?.taxes?.map((tax) => {
                return (
                  <div className="d-flex justify-content-between" key={tax._id}>
                    <h5>{tax?.taxType ?? ""}</h5>
                    <h5>{tax?.percentage ?? 0}%</h5>
                  </div>
                );
              })}
              {
                // <h5>Tax Percentage</h5>
              }
              {
                // <h5>{rateData?.result?.data?.at(0)?.taxPercentage || 0}%</h5>
              }
              {/*<div className="d-flex mt-5 justify-content-between">
                <h5>Total Amount</h5>
                <h5>₹{getTaxedAmount}</h5>
            </div>*/}
            </>
          )}

          {
            // <div className="d-flex flex-column gap-2 align-items-center">
            //   <a
            //     className="view-more-link text-start"
            //     href="https://razorpay.com/terms/"
            //   >
            //     Terms and Conditions
            //   </a>
            //   <a
            //     className="view-more-link text-start"
            //     href="https://razorpay.com/privacy/"
            //   >
            //     Privacy Policy
            //   </a>
            // </div>
          }
        </Card>
      </div>
      {rateByCategoryData?.result?.at(0)?.paymentAmount === 0 && (
    <div className="alert alert-warning w-50" role="alert">
     Application fee is not configured. Please try again later.
    </div>
  )}
      <div className="mx-auto pt-2 d-flex gap-2 mt-auto">
        <Button
          label="Cancel"
          type="button"
          className="btn-default btn-sucess btn-outline shadow-none"
          onClick={() => {
            setModalState({
              title: "Payment Request Cancelled",
              show: true,
              isSuccess: false,
            });
          }}
        />
        <Button
          label="Pay"
          type="button"
          className="btn-default btn-sucess shadow-none"
          onClick={startPayment}
          disabled={rateByCategoryData?.result?.at(0)?.paymentAmount === 0 || isAddOrderLoading}
/>
      </div>
    </div>
  );
}
export { CheckoutPage };
