import { pagination } from "../../../utils/constants";

export const addExistingVessel = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel/existingVessel`,
      method: "post",
      body: data,
    }),
  });

export const addExistingVesselAdditionalDetails = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel-additional-details/existingVessel`,
      method: "post",
      body: data,
    }),
  });

export const fetchExistingUserbyEmailId = (build) => {
  return build.query({
    query: ({ email, searchParams }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      // const url = `/user/byEmail/${email}/${role}`;
      const url = `/user/byEmail/${email}?${params.join("&")}`;
      return url;
    },
  });
};

export const fetchAllExistingVessels = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];

      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/vessel/existingVessel/list/all?${params.join("&")}`;
      return url;
    },
  });
};
export const updateExistingVesselAdditional = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `vessel-additional-details/updateExistingVessel/${id}`,
      method: "PATCH",
      body: data,
    }),
  });

export const approveRejectExistingVessel = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `vessel/updateVesselStatus/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const getRegistration = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/existingVesselRegistrationCertificate/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};
export const getSurveyCertificate = (build) => {
  return build.query({
    query: ({ vesselId }) => ({
      url: `pdf/existingVesselSurveyCertificate/${vesselId}`,
      responseHandler: (response) => response.text(),
    }),
  });
};
export const fetchFileTypes = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `files/types`;
      return url;
    },
  });
};
export const addVesselHistoryChange = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel-history-change`,
      method: "post",
      body: data,
    }),
  });

export const fetchVesselHistoryByVesselId = (build) => {
  return build.query({
    query: ({ vesselId }) => {
      const url = `/vessel-history-change/byVesselId/${vesselId}`;
      return url;
    },
  });
};

export const updateVesselHistory = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `/vessel-history-change/${id}`,
      method: "PATCH",
      body: data,
    }),
  });

export const fetchBuildMaterial = (build) => {
  return build.query({
    query: ({}) => {
      const url = `/masterdata/hullmaterial`;
      return url;
    },
  });
};
export const fetchTypeOfOwnwership = (build) => {
  return build.query({
    query: ({}) => {
      const url = `/masterdata/typeofownership`;
      return url;
    },
  });
};
export const fetchNatureOfFuel = (build) => {
  return build.query({
    query: ({}) => {
      const url = `/masterdata/natureoffuel`;
      return url;
    },
  });
};

export const addCrewExisting = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `/crewdetails/createMany`,
      method: "POST",
      body: data,
    }),
  });
};

export const updateCrewExisting = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `/crewdetails/updateMany`,
      method: "PATCH",
      body: data,
    }),
  });
};

export const fetchAllExistingVesselsbypr = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];

      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `vessel/existingVessel/list/bypr?${params.join("&")}`;
      return url;
    },
  });
};
export const addAdditionalOwner = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/additional-owners`,
      method: "post",
      body: data,
    }),
  });
  export const updateAdditionalOwner = (build) =>
    build.mutation({
      query: ({ data}) => ({
        url: `/additional-owners/updateMany`,
        method: "PATCH",
        body: data,
      }),
    });
    export const fetchAdditionalOwnerByVesselId = (build) => {
      return build.query({
        query: ({ id }) => {
          const url = `/additional-owners/${id}`;
          return url;
        },
      });
    };
    export const deleteAdditionalOwner = (build) => {
      return build.mutation({
        query: ({ id }) => ({
          url: `/additional-owners/${id}`,
          method: "DELETE",
        }),
      });
    };
