import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import CommonPageTitle from "../../../components/CommonPageTitle";
import { useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import TextBox from "../../../components/TextBox";
import Button from "../../../components/Button";
import * as Yup from "yup";
import {
  useAddVesselBuilderFromAdminMutation,
  useAddVesselBuilderMutation,
} from "../../../services/modules/admin/vessel";
import SuccessModal from "../../../components/CommonSuccessModal";
import UserService from "../../../services/UserService";
import CommonModal from "../../../components/CommonModal";
import { useLazyFetchRolebyRoleCodeQuery } from "../../../services/modules/admin/role";
import { useLazyFetchDistrictsQuery } from "../../../services/modules/admin/district";
import SelectBox from "../../../components/Selectbox";
import { useLazyGetOtpQuery } from "../../../services/modules/otp";
import { millisToMinutesAndSeconds } from "../../../utils/utility";
import FileSelector from "../../../components/FileSelector";
import TextBoxLive from "../../../components/TextBoxLive";
import OTPInput from "react-otp-input";
import "./style.scss";
import { uploadFilesInSchema } from "../../../utils/uploadFilesInSchema";
import Loader from "../../../components/Loader";
import { useLazyFetchStateQuery } from "../../../services/modules/admin/state";

const AddBuilder = (props) => {
  const navigate = useNavigate();
  const [roleCode, setRoleCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const [message, setMessage] = useState("");
  const [addVesselBuilder, { data: userData, isLoading: addLoading }] =
    useAddVesselBuilderMutation();

  const [fetchRolebyRoleCode, { data: roleData, isLoading: roleLoading }] =
    useLazyFetchRolebyRoleCodeQuery();

  const [fetchDistricts, { data: districtData }] = useLazyFetchDistrictsQuery();
  const [fetchState, { data: stateData, isLoading: stateLoading }] =
    useLazyFetchStateQuery();
  const [
    getOtp,
    {
      isSuccess: isGetOtpSuccess,
      data: getOtpData,
      isUninitialized: isGetOtpUninitialized,
    },
  ] = useLazyGetOtpQuery();

  const [addVesselBuilderFromAdmin, { data: builderData }] =
    useAddVesselBuilderFromAdminMutation();

  const [userInfo, setUserInfo] = useState(null);

  const onHandleClickBack = () => {
    // navigate("/list-builder");
    if (props.registrationNeeded) {
      UserService.doLogout();
    } else {
      navigate("/list-builder");
    }
  };

  const [message, setMessage] = useState({
    title: "",
    isSuccess: true,
    show: false,
  });
  const initialValues = {
    builderName: "",
    permanentAddress: "",
    temporaryAddress: "",
    selfexplanation: "",
    mobile: "",
    city: "",
    district: "",
    state: "",
    pinCode: "",
    mobile: "",
    email: "",
    otp: "",
    otpId: "",
    signature: "",
    validFrom: "",
    validTo: "",
  };

  const validationSchema = Yup.object({
    permanentAddress: Yup.string()
      .required("Permanent Address is required")
      .matches(
        /^[a-zA-Z0-9.,'()@!&#$-_\s]+$/,
        "Only letters, numbers, and the following special characters are allowed: .,'()@!&#$-_"
      ),
    builderName: Yup.string()
      .required("Builder Name is required!")
      .matches(/^[a-zA-Z0-9\s!@#$%^&*(),.\-+=~`'":;?<>|\/\\[\]{}]+$/, "Invalid characters in builder name."),

    selfexplanation: Yup.string()
      // .required("Self explanation is required!")
      .matches(/^[a-zA-Z ]+$/, "Only characters allowed."),

    mobile: Yup.string()
      .required("Mobile number is required")
      .matches(/^[6-9]\d{9}$/, "Mobile Number is not valid"),

    city: Yup.string()
      .required("City is required")
      .matches(/^[a-zA-Z ]+$/, "Only characters allowed."),
    district: Yup.string().required("District is required"),

    state: Yup.string().required("State is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+$/,
        "Invalid Email Address"
      ),
    mobile: Yup.string()
      .required("Phone number is required")
      .matches(/^[6-9]\d{9}$/, "Phone Number is not valid"),

    pinCode: Yup.string()
      .required("Pin Code is required")
      .matches(/^\d{6}$/, "Invalid Pin Code."),
    validFrom: Yup.string().required("Validity is required"),
    validTo: Yup.string().required("Validity is required"),
  });

  const validationSchemaNew = validationSchema.shape({
    otp: Yup.number().required("OTP is required"),
    signature: Yup.mixed().required("Signature is required"),
  });

  const getValidationSchema = () => {
    return props.registrationNeeded
      ? validationSchema.concat(validationSchemaNew)
      : validationSchema;
  };

  useEffect(() => {
    fetchState({});
  }, [fetchState]);

  useEffect(() => {
    const getUserInfo = async () => {
      const userDetail = await UserService.getUserProfile();
      if (userDetail) {
        setUserInfo(userDetail);
      }
    };
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      // console.log("userInfo", userInfo);
      getRoleCode();
    }
  }, [userInfo]);

  useEffect(() => {
    if (roleCode.length > 0) {
      fetchRolebyRoleCode({ roleCode: roleCode });
    }
  }, [roleCode]);

  const getRoleCode = () => {
    // console.log("userInfo?.attributes", userInfo?.attributes?.role);
    if (userInfo?.attributes?.role?.at(0) == "Vessel Owner") {
      setRoleCode("vessel-owner");
    } else if (userInfo?.attributes?.role?.at(0) == "Builder") {
      setRoleCode("builder");
    } else if (userInfo?.attributes?.role?.at(0) == "Yard Owner") {
      setRoleCode("yard-owner");
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("userInfo", userInfo);

    setSubmitting(true);
    setIsSubmitting(true);
    const {
      builderName,
      permanentAddress,
      temporaryAddress,
      selfexplanation,
      city,
      district,
      state,
      pinCode,
      mobile,
      signature,
      otp,
      email,
      validFrom,
      validTo,
    } = values;

    const emailNewUser = userInfo?.email;

    let postDataCommon = {
      builderName,
      permanentAddress,
      temporaryAddress,
      selfexplanation,
      email: props?.registrationNeeded ? emailNewUser : email,
      mobile,
      city,
      district,
      state,
      pinCode,
      validFrom,
      validTo,
    };
    if (props.registrationNeeded) {
      const data = {
        ...postDataCommon,
        otp: otp,
        otpId: isGetOtpSuccess ? getOtpData.result : "",
        signature,
        uid: userInfo?.id,
      };

      try {
        const uploadedPostData = await uploadFilesInSchema(data);
        const response = await addVesselBuilder(uploadedPostData);
        setMessage({
          title: response?.error
            ? response?.error?.data?.message
            : response?.data?.message,
          show: true,
          isSuccess: !response?.error,
        });
      } catch (error) {}
    } else {
      try {
        const response = await addVesselBuilderFromAdmin(postDataCommon);
        setMessage({
          title: response?.error
            ? response?.error?.data?.message
            : response?.data?.message,
          show: true,
          isSuccess: !response?.error,
        });
      } catch (error) {}
    }
    setSubmitting(false);
    setIsSubmitting(false);
  };
  const handleStateChange = (e) => {
    fetchDistricts({ searchParams: `stateId=${e.target.value}` });
  };
  if (isSubmitting) return <Loader />;

  return (
    <Container className="dashboard-container">
      <Container>
        {props.registrationNeeded && (
          <div className="d-flex justify-content-center">
            <img src="../../../assets/images/default-logo.jpg" alt="KMB LOGO" />
          </div>
        )}
        <CommonModal
          {...message}
          description=""
          buttonText="OK"
          handleClose={() => {
            setMessage((message) => ({
              ...message,
              show: false,
            }));
            if (message.isSuccess)
              if (props.registrationNeeded) props.changeRoutes(roleCode);
              else navigate("/list-builder");
          }}
        />
        {!props.registrationNeeded && (
          <>
            <Breadcrumb crumbs={props.crumbs} />
            <CommonPageTitle
              title={"Builder"}
              arrowback={true}
              handleClickBack={onHandleClickBack}
            />
          </>
        )}

        <Row>
          <Formik
            enableReinitialize={true}
            validationSchema={getValidationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              handleChange,
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form
                className="forms"
                style={{ marginTop: "0px" }}
                onSubmit={handleSubmit}
              >
                {console.log("Formik errors:", errors)}
                <Container>
                  <Col>
                    <Row className="justify-content-center">
                      <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Card className="card p-2">
                          <Container>
                            {!props.registrationNeeded && (
                              <Col md={11}>
                                <h2 className="pt-3 d-flex justify-content-center">
                                  Add Builder{" "}
                                </h2>
                              </Col>
                            )}

                            <Row>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Builder Name"
                                  id="builderName"
                                  name="builderName"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.builderName}
                                  touched={touched.builderName}
                                  error={errors.builderName}
                                  onBlur={handleBlur}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Permanent Address"
                                  id="permanentAddress"
                                  name="permanentAddress"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.permanentAddress}
                                  touched={touched.permanentAddress}
                                  error={errors.permanentAddress}
                                  onBlur={handleBlur}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Temporary Address"
                                  id="temporaryAddress"
                                  name="temporaryAddress"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.temporaryAddress}
                                  touched={touched.temporaryAddress}
                                  error={errors.temporaryAddress}
                                  onBlur={handleBlur}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Self Explanation"
                                  id="selfexplanation"
                                  name="selfexplanation"
                                  type="text"
                                  // required={true}
                                  onChange={handleChange}
                                  value={values.selfexplanation}
                                  touched={touched.selfexplanation}
                                  error={errors.selfexplanation}
                                  onBlur={handleBlur}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <SelectBox
                                  label="Select State"
                                  name="state"
                                  required={true}
                                  isRequired={true}
                                  onChange={(el) => {
                                    handleChange(el);
                                    handleStateChange(el);
                                  }}
                                  value={values.state}
                                  isTouched={touched.state}
                                  error={errors.state}
                                  onBlur={handleBlur}
                                  isIdSelect={true}
                                  options={stateData?.result?.data ?? []}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <SelectBox
                                  label="Select District"
                                  name="district"
                                  required={true}
                                  isRequired={true}
                                  onChange={(el) => {
                                    setFieldValue("district", el.target.value);
                                  }}
                                  value={values.district}
                                  isTouched={touched.district}
                                  error={errors.district}
                                  onBlur={handleBlur}
                                  isIdSelect={true}
                                  options={districtData?.result?.data ?? []}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="City"
                                  id="city"
                                  name="city"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.city}
                                  touched={touched.city}
                                  error={errors.city}
                                  onBlur={handleBlur}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Pin Code"
                                  id="pinCode"
                                  name="pinCode"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.pinCode}
                                  touched={touched.pinCode}
                                  error={errors.pinCode}
                                  onBlur={handleBlur}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Validity of existing builder from"
                                  name="validFrom"
                                  type="date"
                                  placeholder="Enter"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.validFrom}
                                  touched={touched.validFrom}
                                  error={errors.validFrom}
                                  onBlur={handleBlur}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Validity of existing builder to"
                                  name="validTo"
                                  type="date"
                                  placeholder="Enter"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.validTo}
                                  touched={touched.validTo}
                                  error={errors.validTo}
                                  onBlur={handleBlur}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Email"
                                  id="email "
                                  name="email"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.email}
                                  validateOnChange={true}
                                  onBlur={handleBlur}
                                  error={errors.email}
                                  touched={touched.email}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBox
                                  label="Phone Number"
                                  id="mobile"
                                  name="mobile"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.mobile}
                                  error={errors.mobile}
                                  touched={touched.mobile}
                                  onBlur={handleBlur}
                                />
                              </Col>
                              {props.registrationNeeded && (
                                <>
                                  <OtpButton
                                    mobile={values.mobile}
                                    getOtp={getOtp}
                                    error={errors.mobile}
                                  />

                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    xxl={6}
                                  >
                                    <label className="mt-2 required-indicator">
                                      {" "}
                                      OTP{" "}
                                    </label>
                                    <div className="otp-container">
                                      <OTPInput
                                        value={values.otp}
                                        onChange={(value) => {
                                          setFieldValue("otp", value);
                                        }}
                                        numInputs={6}
                                        renderSeparator={
                                          <span
                                            style={{ marginLeft: "3px" }}
                                          ></span>
                                        }
                                        renderInput={(props) => (
                                          <input
                                            {...props}
                                            disabled={
                                              !values?.mobile ||
                                              errors?.mobile ||
                                              isGetOtpUninitialized
                                            }
                                          />
                                        )}
                                        inputStyle="inputStyle"
                                      />
                                    </div>
                                  </Col>

                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    xxl={6}
                                    className="mt-2"
                                  >
                                    <FileSelector
                                      label={"Upload Signature"}
                                      name={"signature"}
                                      id={"signature"}
                                      onBlur={handleBlur}
                                      touched={touched.signature}
                                      required={true}
                                      error={errors.signature}
                                      onChange={(e) => {
                                        if (
                                          e.target.files &&
                                          e.target.files.length === 1
                                        ) {
                                          setFieldValue(
                                            "signature",
                                            e.target.files[0]
                                          );
                                        }
                                      }}
                                      accept="image/*"
                                    />
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Container>
                          <Container className="container mt-3 mb-3">
                            <Row className="button-container">
                              <Col className="btn-col address-button d-flex justify-content-center">
                                <Button
                                  label="Cancel"
                                  type="button"
                                  className="btn-sucess float-right btn-outline"
                                  onClick={() => {
                                    if (props.registrationNeeded) {
                                      UserService.doLogout();
                                      localStorage.clear();
                                    } else {
                                      navigate("/list-builder");
                                    }
                                  }}
                                />
                                <Button
                                  label="Submit"
                                  type="submit"
                                  className="btn-sucess float-right btn-default"
                                  disabled={isSubmitting}
                                  onClick={handleSubmit}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Container>
              </form>
            )}
          </Formik>
        </Row>
      </Container>
    </Container>
  );
};

function OtpButton({ mobile, getOtp, error }) {
  const OTP_TIMER = 2 * 60 * 1000;
  const [otpTime, setOtpTime] = useState(0);

  useEffect(() => {
    if (otpTime > 0) {
      const timerId = setInterval(() => {
        setOtpTime((prev) => prev - 1000);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [otpTime]);

  return (
    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
      <TextBoxLive
        isDisabled={otpTime !== 0 || !mobile || error}
        value={
          otpTime === 0
            ? "Get OTP"
            : `Resend otp in ${millisToMinutesAndSeconds(otpTime)}`
        }
        label="&nbsp;"
        type="button"
        onClick={() => {
          if (!mobile) return;
          getOtp({
            data: {
              phone: mobile,
            },
          }).then((_) => {
            setOtpTime(OTP_TIMER);
          });
        }}
      />
    </Col>
  );
}

export default AddBuilder;
