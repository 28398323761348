import MasterServiceAPI from "../../MasterServiceAPI";
import {
  addExistingVessel,
  addExistingVesselAdditionalDetails,
  fetchExistingUserbyEmailId,
  fetchAllExistingVessels,
  updateExistingVesselAdditional,
  approveRejectExistingVessel,
  getRegistration,
  getSurveyCertificate,
  fetchFileTypes,
  addVesselHistoryChange,
  fetchVesselHistoryByVesselId,
  updateVesselHistory,
  fetchBuildMaterial,
  fetchNatureOfFuel,
  addCrewExisting,
  updateCrewExisting,
  fetchAllExistingVesselsbypr,fetchTypeOfOwnwership,
  addAdditionalOwner,updateAdditionalOwner,
  fetchAdditionalOwnerByVesselId,deleteAdditionalOwner
} from "./ExistingVessel";

export const ExistingVesselAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addExistingVessel: addExistingVessel(build),
    addExistingVesselAdditionalDetails:
      addExistingVesselAdditionalDetails(build),
    fetchExistingUserbyEmailId: fetchExistingUserbyEmailId(build),
    fetchAllExistingVessels: fetchAllExistingVessels(build),
    updateExistingVesselAdditional: updateExistingVesselAdditional(build),
    approveRejectExistingVessel: approveRejectExistingVessel(build),
    getRegistration: getRegistration(build),
    getSurveyCertificate: getSurveyCertificate(build),
    fetchFileTypes: fetchFileTypes(build),
    updateExistingVesselAdditional: updateExistingVesselAdditional(build),
    approveRejectExistingVessel: approveRejectExistingVessel(build),
    addVesselHistoryChange: addVesselHistoryChange(build),
    fetchVesselHistoryByVesselId: fetchVesselHistoryByVesselId(build),
    updateVesselHistory:   updateVesselHistory(build),
    addCrewExisting: addCrewExisting(build),
    updateCrewExisting: updateCrewExisting(build),
    fetchBuildMaterial: fetchBuildMaterial(build),
    fetchNatureOfFuel: fetchNatureOfFuel(build),
    fetchAllExistingVesselsbypr:fetchAllExistingVesselsbypr(build),
    fetchTypeOfOwnwership:fetchTypeOfOwnwership(build),
    addAdditionalOwner:addAdditionalOwner(build),
    updateAdditionalOwner:updateAdditionalOwner(build),
    fetchAdditionalOwnerByVesselId:fetchAdditionalOwnerByVesselId(build),
    deleteAdditionalOwner:deleteAdditionalOwner(build)
  }),
  overrideExisting: false,
});

export const {
  useAddExistingVesselMutation,
  useAddExistingVesselAdditionalDetailsMutation,
  useLazyFetchExistingUserbyEmailIdQuery,
  useLazyFetchAllExistingVesselsQuery,
  useUpdateExistingVesselAdditionalMutation,
  useApproveRejectExistingVesselMutation,
  useLazyGetRegistrationQuery,
  useLazyGetSurveyCertificateQuery,
  useLazyFetchFileTypesQuery,
  useLazyFetchVesselHistoryByVesselIdQuery,
  useUpdateVesselHistoryMutation,
  useAddVesselHistoryChangeMutation,
  useLazyFetchBuildMaterialQuery,
  useLazyFetchNatureOfFuelQuery,
  useAddCrewExistingMutation,
  useUpdateCrewExistingMutation,
  useLazyFetchAllExistingVesselsbyprQuery,
  useLazyFetchTypeOfOwnwershipQuery,
  useAddAdditionalOwnerMutation,
  useUpdateAdditionalOwnerMutation,
  useLazyFetchAdditionalOwnerByVesselIdQuery,
  useDeleteAdditionalOwnerMutation
} = ExistingVesselAPI;
