import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Accordion } from "react-bootstrap";
import TextArea from "./TextArea";
import { useFormik } from "formik";
import { AiOutlineDownload } from "react-icons/ai";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Config } from "../config";
import { sideEngineLabel } from "../utils/constants";

function RequestDetails({
  userDetails,
  vesselData,
  builderDetails,
  yardDetails,
  vesselDataAdditional,
  propulsionEngine,
  equipments,
}) {
  // Navigate

  const navigate = useNavigate();

  const [dataVisible, setDataVisible] = useState(false);
  const [expanded, setExpanded] = useState(false); // State variable to track expansion

  // Function to toggle expansion
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const fontStyle = {
    color: "#7f8087",
    fontSize: "14px ",
  };
  // Fire Appliance data variables
  const datamain = vesselDataAdditional?.fireAppliance?.firePumps || "";
  const datapump = vesselDataAdditional?.fireAppliance?.fireMains || "";
  //Hulls data variables
  const hullsitem = vesselDataAdditional?.hulls?.BulkHeads || "";
  console.log("hullsitem", vesselDataAdditional?.hulls?.BulkHeads);

  const RenderParticularsofHulls = () => {
    return (
      <div>
        <h4 className="owner-head">Particulars of hulls</h4>
        {/* {builderDetails &&
          builderDetails.map((builder, index) => ( */}
        <Row className="d-flex">
          <Row>
            <Col md={4} className="mb-2">
              <p className="field ">Builders Name</p>
              <p className="display-field fw-bold">
                {builderDetails?.builderName}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Builders Address</p>
              <p className="display-field fw-bold scrollable-horizontal">
                {builderDetails?.permanentAddress}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Builders City</p>
              <p className="display-field fw-bold">{builderDetails?.city}</p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Builders District</p>
              <p className="display-field fw-bold">
                {builderDetails?.district?.districtName}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Builders State</p>
              <p className="display-field fw-bold">
                {builderDetails?.state?.stateName}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Builders Pincode</p>
              <p className="display-field fw-bold">{builderDetails?.pinCode}</p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Material of the hull</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.hulls?.materialOfHull ?? ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">
                Whether with a deck above free board deck
              </p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.hulls?.freeboarddeck ?? ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Passenger Capacity</p>
              <p className="display-field fw-bold">
                {/* Add Passenger Field Values */}
                {vesselDataAdditional?.passengerCapasity ?? ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Length Overall (in meters)</p>
              <p className="display-field fw-bold">
                {/* Add Length Overall   Values */}
                {vesselDataAdditional?.lengthOverall ?? ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Length on Deck (in meters) </p>
              <p className="display-field fw-bold">
                {/* Add Length on Deck Values */}
                {vesselDataAdditional?.lengthOnDeck ?? ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Breadth (in meters) </p>
              <p className="display-field fw-bold">
                {/* Add Breadth Values */}
                {vesselDataAdditional?.breadth ?? ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Depth (in meters)</p>
              <p className="display-field fw-bold">
                {/* Add Breadth Values */}
                {vesselDataAdditional?.depth ?? ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Yard </p>
              <p className="display-field fw-bold">
                {/* Add Breadth Values */}
                {yardDetails?.yardName ?? ""}
              </p>
            </Col>
          </Row>
        </Row>
        {/* ))} */}
        <Row>
          <Row>
            <Col md={4} className="mb-3">
              <p style={fontStyle}> Bulk Heads</p>
            </Col>
          </Row>
          {hullsitem &&
            hullsitem.map((val, index) => (
              <Row key={index}>
                <Col
                  md={2}
                  className="d-flex align-items-center px-5 justify-content-end "
                >
                  <div className="text-black">{index + 1}</div>
                </Col>
                <Col md={10} className="d-flex gap-4">
                  <Col md={3}>
                    <p style={fontStyle}>Bulk Head Placement</p>
                    <p
                      className="display-field"
                      style={{ fontWeight: "bolder" }}
                    >
                      {val?.placementOfBulkHeads}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p style={fontStyle}>Bulk Head Thickness (in meters)</p>
                    <p
                      className="display-field"
                      style={{ fontWeight: "bolder" }}
                    >
                      {val?.thicknessOfBulkHeads}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p style={fontStyle}>Hull Plating Material</p>
                    <p
                      className="display-field"
                      style={{ fontWeight: "bolder" }}
                    >
                      {val?.hullPlatingMaterial}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p style={fontStyle}>Hull Plating Thickness (in meters)</p>
                    <p
                      className="display-field"
                      style={{ fontWeight: "bolder" }}
                    >
                      {val?.hullPlatingThickness}
                    </p>
                  </Col>
                </Col>
              </Row>
            ))}
        </Row>
      </div>
    );
  };

  const RenderPropulsionofEngine = () => {
    console.log(propulsionEngine, "PROP");
    return (
      <div>
        <h4 className="owner-head">Particulars of propulsion of engines</h4>
        {propulsionEngine &&
          propulsionEngine.map((engine, index) => (
            <Row className="d-flex">
              <h4>Engine {index + 1}</h4>
              <Row>
                <Col md={4} className="mb-3">
                  <p className="field ">Number of sets with BHP of each</p>
                  <p className="display-field fw-bold">
                    {engine?.noOfSets || ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">Manufacturers Name/Brand</p>
                  <p className="display-field fw-bold">
                    {engine?.manufacturerNameOrBrand || ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">Model Number</p>
                  <p className="display-field fw-bold">
                    {engine?.modelNo || ""}
                  </p>
                </Col>
                <Col md={4} className="mb-3">
                  <p className="field ">Type of Engine</p>
                  <p className="display-field fw-bold">
                    {engine?.typeOfEngine || ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">Diameter of propulsion shaft (in )</p>
                  <p className="display-field fw-bold">
                    {engine?.diameterOfPropulsionShaft || ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">Material of propulsion shaft</p>
                  <p className="display-field fw-bold">
                    {engine?.materialOfPropulsionShaft || ""}
                  </p>
                </Col>
                <Col md={4} className="mb-3">
                  <p className="field ">No of gears</p>
                  <p className="display-field fw-bold">
                    {engine?.noOfGears || ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field">Type of gears</p>
                  <p className="display-field fw-bold">
                    {engine?.typeOfGears || ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field">Side of Engine</p>
                  <p className="display-field fw-bold">
                    {/* {sideEngineLabel.find(engine?.sideOfEngine) || ""} */}
                    {(() => {
                      const sideEngine = sideEngineLabel.find(
                        (labelObj) => labelObj.key === engine?.sideOfEngine
                      );
                      console.log(sideEngine, "sideofEngine");
                      return sideEngine ? sideEngine.label : "";
                    })()}
                  </p>
                </Col>
              </Row>
            </Row>
          ))}
      </div>
    );
  };

  const RenderParticularsofEquipments = () => {
    return (
      <div>
        <h4 className="owner-head">Particulars of equipment</h4>
        <Row className="d-flex">
          <Row>
            <Col md={4} className="mb-3">
              <p className="field ">Anchor-Port weight (kg)</p>
              <p className="display-field fw-bold">
                {equipments?.anchorPort?.weight || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field">Anchor-Port Material</p>
              <p className="display-field  fw-bold">
                {equipments?.anchorPort?.material || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Starboard weight (kg)</p>
              <p className="display-field fw-bold">
                {equipments?.starboard?.weight || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Starboard Material</p>
              <p className="display-field fw-bold">
                {equipments?.starboard?.material || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Anchor-Spare weight (kg)</p>
              <p className="display-field fw-bold">
                {equipments?.anchorSpare?.weight || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Anchor-Spare Material</p>
              <p className="display-field fw-bold">
                {equipments?.anchorSpare?.material || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Port-Chain Size</p>
              <p className="display-field fw-bold">
                {equipments?.portChain?.size || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Port-Chain Type</p>
              <p className="display-field fw-bold">
                {equipments?.portChain?.type || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Port-Chain Length (in meters)</p>
              <p className="display-field fw-bold">
                {equipments?.portChain?.length || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field">Starboard-Chain Size</p>
              <p className="display-field  fw-bold">
                {equipments?.starboardChain?.size || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Starboard-Chain Type</p>
              <p className="display-field fw-bold">
                {equipments?.starboardChain?.type || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Starboard-Chain Length (in meters)</p>
              <p className="display-field fw-bold">
                {equipments?.starboardChain?.length || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Ropes Size</p>
              <p className="display-field fw-bold">
                {equipments?.ropes?.size || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Ropes Material</p>
              <p className="display-field fw-bold">
                {equipments?.ropes?.material || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">No of Ropes</p>
              <p className="display-field fw-bold">
                {equipments?.ropes?.number || ""}
              </p>
            </Col>

            <Col md={4}>
              <p className="field ">Search Lights Number</p>
              <p className="display-field fw-bold">
                {equipments?.searchLights?.number || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Search Lights Size</p>
              <p className="display-field fw-bold">
                {equipments?.searchLights?.size || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Search Lights Power</p>
              <p className="display-field fw-bold">
                {equipments?.searchLights?.powder || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Life buoys</p>
              <p className="display-field fw-bold">
                {equipments?.lifebuoys || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">
                Buoyant apparatus with self ignited light/ buoyant lanyard
              </p>
              <p className="display-field fw-bold">
                {equipments?.buoyantLanyard || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Navigation Lights giving particulars</p>
              <p className="display-field fw-bold">
                {equipments?.navigationLights || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Sound Signals</p>
              <p className="display-field fw-bold">
                {equipments?.soundSignals || ""}
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    );
  };

  const RenderParticularofFireAppliance = () => {
    return (
      <div>
        <h4 className="owner-head">Particulars of fire appliance</h4>
        <Row className="d-flex">
          <div style={{ marginBottom: "30px" }}>
            <Row className="d-flex">
              <Row>
                <Row>
                  <Col md={4} className="mb-3">
                    <p style={fontStyle}>No of Fire Pumps</p>
                  </Col>
                </Row>
                {datamain &&
                  datamain.map((value, index) => (
                    <>
                      <Row
                        key={index}
                        className="d-flex justify-content-evenly "
                      >
                        <Col
                          md={2}
                          className="d-flex align-items-center  justify-content-end "
                        >
                          <div className="rounded-5 px-3 py-2  text-black">
                            {index + 1}
                          </div>
                        </Col>
                        <Col md={4}>
                          <p style={fontStyle}>Size of Fire Pumps</p>
                          <p
                            className="display-field"
                            style={{ fontWeight: "bolder" }}
                          >
                            {value.size}
                          </p>
                        </Col>
                        <Col md={4}>
                          <p className=" no-wrap" style={fontStyle}>
                            Capacity of Fire Pumps
                          </p>
                          <p
                            className="display-field"
                            style={{ fontWeight: "bolder" }}
                          >
                            {value.capasity}
                          </p>
                        </Col>
                      </Row>
                    </>
                  ))}
                <Row>
                  <Col md={4} className="mb-3">
                    <p style={fontStyle}> No of Fire Mains</p>
                  </Col>
                </Row>
                <Row className="d-flex">
                  {datapump &&
                    datapump.map((value, index) => (
                      <Row
                        key={index}
                        className="d-flex justify-content-evenly"
                      >
                        <Col
                          md={2}
                          className="d-flex align-items-center justify-content-end"
                        >
                          <div className="rounded-5 px-3 py-2  text-black">
                            {index + 1}
                          </div>
                        </Col>
                        <Col md={4}>
                          <p style={fontStyle}>Fire Pumps Diameter</p>
                          <p
                            className="display-field"
                            style={{ fontWeight: "bolder" }}
                          >
                            {value.diameter}
                          </p>
                        </Col>
                        <Col md={4}>
                          <p style={fontStyle}>Fire Pumps Material</p>
                          <p
                            className="display-field"
                            style={{ fontWeight: "bolder" }}
                          >
                            {value.maerial}
                          </p>
                        </Col>
                      </Row>
                    ))}
                </Row>
                <Col md={4} className="mb-3">
                  <p className="field ">No of Hydrants</p>
                  <p className="display-field fw-bold">
                    {vesselDataAdditional?.fireAppliance?.numberOfHydrants ??
                      ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">No of Hose</p>
                  <p className="display-field fw-bold">
                    {vesselDataAdditional?.fireAppliance?.noOfHose ?? ""}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">Nozzles</p>
                  <p className="display-field fw-bold">
                    {vesselDataAdditional?.fireAppliance?.nozzles ?? ""}
                  </p>
                </Col>
                <Col md={4} className="mb-3">
                  <p className="field ">Any other equipments</p>
                  <p className="display-field fw-bold">
                    {vesselDataAdditional?.fireAppliance?.otherEquipment ?? ""}
                  </p>
                </Col>
              </Row>
            </Row>
          </div>
        </Row>
      </div>
    );
  };

  const RenderAdditionalInformations = () => {
    return (
      <div>
        <h4 className="owner-head">Additional Informations</h4>
        <Row className="d-flex">
          <Row>
            <Col md={4} className="mb-3">
              <p className="field ">
                No of portable fire extinguisher with particulars
              </p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.fireExtinguisher?.number || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Soda Acid</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.fireExtinguisher?.sodaAcid || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Foam</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.fireExtinguisher?.foam || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Dry Powder</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.fireExtinguisher?.dryPowder || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Any other types</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.fireExtinguisher?.otherType || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Particulars of communication equipments</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.communicationEquipment || ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Particulars of pollution control devices</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.pollutionControlDevices?.particulars ||
                  ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Sewage treatment and disposal</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.pollutionControlDevices
                  ?.sewageTreatment || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Solid waste processing and disposal</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.pollutionControlDevices?.solidWaste ||
                  ""}
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <p className="field ">Sound pollution control</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.pollutionControlDevices
                  ?.soundPollutionCntrl || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Water consumption/day</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.pollutionControlDevices
                  ?.WaterConsumption || ""}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Source of water</p>
              <p className="display-field fw-bold">
                {vesselDataAdditional?.pollutionControlDevices?.sourceOfWater ||
                  ""}
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    );
  };

  return (
    <Container className="dashboard-container">
      <div className="card">
        <Accordion alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Vessel Requests</Accordion.Header>
            <Accordion.Body>
              <h4 className="my-3">Owner Details</h4>
              <Row className="d-flex">
                <Row>
                  <Col md={4} className="mb-3">
                    <p className="field ">Request type</p>
                    <p className="display-field fw-bold">
                      {vesselData?.requestType?.preApprovalType ?? ""}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">District</p>
                    <p className="display-field fw-bold">
                      {vesselData?.district?.districtName ?? ""}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Port of Registry</p>
                    <p className="display-field fw-bold">
                      {vesselData?.portRegOfVessel?.name ?? ""}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Port</p>
                    <p className="display-field fw-bold">
                      {vesselData?.port?.portName ?? ""}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Owner Name</p>
                    <p className="display-field fw-bold">
                      {userDetails?.firstName}
                    </p>
                  </Col>
                  <Col md={4} className="mb-3">
                    <p className="field ">Owner House Name/No</p>
                    <p className="display-field fw-bold">
                      {userDetails?.houseName}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Owner City</p>
                    <p className="display-field fw-bold">{userDetails?.city}</p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Owner District</p>
                    <p className="display-field fw-bold">
                      {userDetails?.district?.districtName}
                    </p>
                  </Col>
                  <Col md={4} className="mb-3">
                    <p className="field ">Owner State</p>
                    <p className="display-field fw-bold">Kerala</p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Owner Pincode</p>
                    <p className="display-field fw-bold">
                      {userDetails?.pinCode}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Name of Vessel</p>
                    <p className="display-field fw-bold">
                      {vesselData?.vesselName ?? ""}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Category of Vessel</p>
                    <p className="display-field fw-bold">
                      {vesselData?.vesselCategory?.vesselCategoryName ?? ""}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Type of Vessel</p>
                    <p className="display-field fw-bold">
                      {vesselData?.vesselType?.vesselTypeName ?? ""}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="field ">Sub Type of Vessel</p>
                    <p className="display-field fw-bold">
                      {vesselData?.vesselSubType?.vesselSubTypeName ?? ""}
                    </p>
                  </Col>
                  {vesselDataAdditional?.additionalCapacity && (
                    <Col md={4}>
                      <p className="field ">Additional Capacity</p>
                      <p className="display-field fw-bold">
                        {vesselDataAdditional?.additionalCapacity ?? ""}
                      </p>
                    </Col>
                  )}
                </Row>
              </Row>

              {!expanded && (
                <p className="view-more-link" onClick={toggleExpansion}>
                  View More
                </p>
              )}

              {/* Additional details to display when expanded */}

              {expanded && (
                <>
                  {/* Call functions to render additional details */}
                  {RenderParticularsofHulls()}
                  {RenderPropulsionofEngine()}
                  {RenderParticularsofEquipments()}
                  {RenderParticularofFireAppliance()}
                  {RenderAdditionalInformations()}

                  {/* Render "View Less" link */}
                  <p className="view-more-link" onClick={toggleExpansion}>
                    View Less
                  </p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Container>
  );
}

export default RequestDetails;
