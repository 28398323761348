import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  fetchFileById,
  fetchFiles,
  uploadFile,
  deleteFile,
  deleteFileByName,
  downloadFile,
} from "./fileHandler";
import {
  generatePdf,
  getSurveyDeclarationPdf,
  getAnnualSurvey,
  getCertificateofSurvey,
  getCarvingNote,
  getRegistrationCertificate,
  getPreApprovalPreview,
  getSurveyPreview,
  getPreApprovalPreviewApplication
} from "./pdfGenerate";

export const FileHandlerApi = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    uploadFile: uploadFile(build),
    fetchFiles: fetchFiles(build),
    fetchFileById: fetchFileById(build),
    deleteFile: deleteFile(build),
    deleteFileByName:deleteFileByName(build),
    generatePdf: generatePdf(build),
    getSurveyDeclarationPdf: getSurveyDeclarationPdf(build),
    getAnnualSurvey: getAnnualSurvey(build),
    getCertificateofSurvey: getCertificateofSurvey(build),
    getCarvingNote: getCarvingNote(build),
    downloadFile: downloadFile(build),
    getRegistrationCertificate: getRegistrationCertificate(build),
    getPreApprovalPreview: getPreApprovalPreview(build),
    getSurveyPreview: getSurveyPreview(build),
    getPreApprovalPreviewApplication:getPreApprovalPreviewApplication(build)

  }),
  overrideExisting: false,
});

export const {
  useUploadFileMutation,
  useLazyFetchFilesQuery,
  useLazyGetSurveyDeclarationPdfQuery,
  useLazyFetchFilesByIdQuery,
  useDeleteFileMutation,
  useDeleteFileByNameMutation,
  useLazyGeneratePdfQuery,
  useLazyGetAnnualSurveyQuery,
  useLazyGetCertificateofSurveyQuery,
  useGetCarvingNoteQuery,
  useLazyGetCarvingNoteQuery,
  useLazyDownloadFileQuery,
  useLazyGetRegistrationCertificateQuery,
  useLazyGetPreApprovalPreviewQuery,
  useLazyGetSurveyPreviewQuery,
  useLazyGetPreApprovalPreviewApplicationQuery
} = FileHandlerApi;
